export type OrderOfDayItem = {
  title: string;
  time: string;
};
export const orderOfDayData: OrderOfDayItem[] = [
  {
    title: "Guests arrive",
    time: "1:30pm",
  },
  {
    title: "Guests are seated",
    time: "1:45pm",
  },
  {
    title: "Ceremony",
    time: "2:00pm",
  },
  {
    title: "Confetti & Canapes",
    time: "2:30pm",
  },
  {
    title: "Wedding Breakfast",
    time: "4:00pm",
  },
  {
    title: "Speeches",
    time: "5:00pm",
  },
  {
    title: "Cut the cake",
    time: "5:30pm",
  },
  {
    title: "Tea & coffee",
    time: "5:45pm",
  },
  {
    title: "Reception guests arrive",
    time: "7:00pm",
  },
  {
    title: "First dance",
    time: "7:30pm",
  },
  {
    title: "Evening buffet",
    time: "8:00pm",
  },
  {
    title: "Last dance",
    time: "11:45pm",
  },
];
