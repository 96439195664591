import * as React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import { Button } from "../components/button";
import { orderOfDayData } from "../data/order-of-day";
import { Input } from "../components/input";

interface AdditionalInfoPageProps {
  data: {
    file: {
      childImageSharp: {
        fluid: any;
      };
    };
  };
}

const AdditionalInfoPage = (props: AdditionalInfoPageProps) => {
  const [formState, setFormState] = React.useState({
    artist: "",
    songName: "",
  });
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<null | string>(null);
  const [success, setSuccess] = React.useState<null | string>(null);

  return (
    <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
      <Img
        fluid={props.data.file.childImageSharp.fluid}
        alt=""
        style={{
          maxHeight: 400,
          backgroundColor: "#D3D9DD",
        }}
      />
      <div className="mobile-column">
        <div style={{ textAlign: "center", padding: 20 }}>
          <h3
            style={{
              marginTop: 20,
              fontFamily: `'Great Vibes', cursive`,
              fontSize: "28pt",
              fontWeight: 100,
            }}
          >
            Order of the day
          </h3>
          {orderOfDayData.map((item) => (
            <div
              key={item.title + item.time}
              style={{
                fontFamily: "Raleway",
                fontSize: "14pt",
                fontWeight: 200,
                margin: 3,
                padding: 0,
              }}
            >
              <p style={{ fontWeight: 1000, marginBottom: 0 }}>{item.time}</p>
              <p style={{ marginBottom: 0 }}>{item.title}</p>
            </div>
          ))}
        </div>
        <form
          name="song-requests"
          style={{ textAlign: "center", padding: 20, maxWidth: 500 }}
          onSubmit={(event) => {
            event.preventDefault();
            setError(null);
            setSuccess(null);
            if (!formState.artist || !formState.songName) {
              setError("Set the artist and song name first");
              return;
            }
            setLoading(true);
            fetch(
              "https://i7btwgv8mg.execute-api.eu-west-1.amazonaws.com/default/weddingFormResponse",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/x-www-form-urlencoded",
                },
                body: JSON.stringify({
                  ...formState,
                  "form-name": "song-requests",
                }),
              }
            )
              .then((_res) => {
                setLoading(false);
                setFormState({ artist: "", songName: "" });
                setSuccess("Thank you for your submission");
              })
              .catch((_err) => {
                setLoading(false);
                setError("Server error, please try again later");
              });
          }}
        >
          <h3
            style={{
              marginTop: 20,
              fontFamily: `'Great Vibes', cursive`,
              fontSize: "28pt",
              fontWeight: 100,
            }}
          >
            Song Requests
          </h3>
          <p
            style={{
              fontFamily: "Raleway",
              fontWeight: 200,
              fontSize: "16pt",
            }}
          >
            Let us know your favourite songs so we can play them at the
            Reception!
          </p>
          <Input
            label="Song Name"
            id="song-name"
            value={formState.songName}
            onChange={(event) => {
              const { value } = event.target;
              setFormState((x) => ({ ...x, songName: value }));
            }}
          />
          <Input
            label="Artist"
            id="artist"
            value={formState.artist}
            onChange={(event) => {
              const { value } = event.target;
              setFormState((x) => ({ ...x, artist: value }));
            }}
          />
          <Button disabled={loading}>
            {loading ? "Loading..." : "Request a song"}
          </Button>
          {success && (
            <p
              style={{
                fontFamily: "Raleway",
                color: "#57BC07",
                fontWeight: 600,
                marginTop: 20,
              }}
            >
              {success}
            </p>
          )}
          {error && (
            <p
              style={{
                fontFamily: "Raleway",
                color: "#CE0000",
                fontWeight: 600,
                marginTop: 20,
              }}
            >
              {error}
            </p>
          )}
        </form>
      </div>
    </div>
  );
};

export const pageQuery = graphql`
  query AdditionalInfoQuery {
    file: file(relativePath: { eq: "car.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default AdditionalInfoPage;
