import * as React from "react";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  id: string;
  label: string;
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ label, ...props }, ref) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: 15,
          marginBottom: 10,
          fontFamily: "Raleway",
        }}
      >
        <label
          style={{
            alignSelf: "flex-start",
            fontSize: "12pt",
          }}
        >
          {label}
        </label>
        <input
          {...props}
          ref={ref}
          style={{
            borderRadius: 10,
            paddingLeft: 10,
            paddingRight: 10,
            boxShadow: "4px 4px 16px #aaaaaaB5",
            height: "40px",
          }}
        />
      </div>
    );
  }
);
